/* TODO: renovate our over 10 years old css/sass */

/* --- Theme specific variables -------- */

$layout-breakpoint-small: 640px;
$layout-lg-container-width: 920px;
   
/* --- Imports ---------------------------------------------------- */
@import "npm:modern-normalize/modern-normalize.css";
@import "npm:@fontsource/titillium-web/latin-200.css";
@import "npm:@fontsource/titillium-web/latin-400.css";

@import "print.scss" print;


/* --- Custom styles ---------------------------------------------------- */
html {
    overflow-y: scroll;
    scroll-behavior: smooth;
    }
body {
    background-color: white;
    padding: 0;
    font-family: 'Titillium Web', 'Helvetica Neue', Helvetica, sans-serif;
    font-weight: 200;
    font-size: large;
    line-height: 140%;
    color: black;
    position: relative;
    }
h1, h2, h3, h4, h5, h6 {
    color: #36297C;
    font-weight: 200;
    font-size: xx-large;
    line-height: 120%;
    margin: 1em 0;
    }
ul {
    list-style-image: url(/src/static/theme/bullet.png);
    padding-left: 0.9em;
    }
li {
    margin: 0.5em 0;
    }
abbr[title] {
    border: none;
    }
strong {
    color: #3E3E3E;
    font-weight: 400;
    }
hr {
    clear: both;
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: silver;
    margin-top: 5px;
    }
th,
td {
    vertical-align: top;
    }
th {
    font-weight: 200;
    text-align: left;
    padding: 0 2em 0 0;
}

a 		    { text-decoration: none; }
a:hover 	{ text-decoration: underline; }

a[href$=".pdf"]   { padding-left: 27px; background: url(/src/static/theme/pdf.png) no-repeat 0 center;  }

a[href^="mailto:"],
a[href^="tel:"] {
    padding-left: 18px; 
    background-repeat: no-repeat;
    background-position: 0 center; 
}

a[href^="mailto:"]	{ background-image: url(/src/static/theme/mail.png); }
a[href^="tel:"]		{ background-image: url(/src/static/theme/phone.svg); }


a.external, 
a[href*="//"]:not([href*="www.waldorflehrerseminar-freiburg.de"]) { 
    padding-right: 18px; background: url(/src/static/theme/external.png) no-repeat 100% center;
}


blockquote {
    margin: 2em;
    font-style: italic;
    }
blockquote footer {
    display: block;
    padding-top: 0.5em;
    text-align: right;
    font-style: normal;
    font-size: medium;
    }
blockquote:before {
    color: rgb(153, 153, 153);
    content: "“";
    display: block;
    font: italic 500%/1 Georgia,"Times New Roman",serif;
    height: 0;
    margin-left: -0.5em;
    }

h1 {
    padding: 0em 0 0.5em 0;
    text-indent: 0em;
    clear: both;
    position: relative;
    }
dt em {
    font-size: medium;
    font-style: normal;
    font-weight: 400;
    margin-left: 0.5em;
    }
.hidden {
    position: absolute;
    left: -999em;
    }
.clear {
    clear: both;
    }
hr.clear {
    clear: both;
    visibility: hidden;
}       

/* -----------------------------------------------------------------------------
    dozenten
------------------------------------------------------------------------------*/
.page--dozenten main {
    h2 {
        clear: both;
        color: #36297C;
        font-weight: 200;
        font-size: xx-large;
        margin: 0 0 1em 0;
    }
    img {
        float: right;
        width: 200px;
        border: 1px solid silver;
        border-radius: 3px;
        padding: 3px;
        margin: 1em 0 1em 2em;
    }
    hr {
        margin-top: 1em;
    }

    @media (max-width: $layout-breakpoint-small) {
        img {
            float: none;
        }
        h2 {
            margin-top: 2em;
            padding-top: 0;
        }
    }
}

/* -----------------------------------------------------------------------------
    header/wortbildmarke
------------------------------------------------------------------------------*/
header {
    z-index: 9;
    position: relative;
    margin: 0.5em 0 3px 0;
    // border-bottom: 1px solid silver;
    color: #36297C;
    font-weight: 200;
    font-size: xx-large;
    line-height: 75%;

    a {
        display: block;
        width: 450px;
        overflow: hidden;
        position: relative;
        left: 50%;
        margin-left: -440px;
    }
    img {
        border: none;
        padding: 0 0 0.5em 0;
        float: left;
    }
    div {
        float: left;
        width: 500px;
        padding-left: 0.75em;
    }
    span {
        color: black;
        display: block;
    }
    span.one {
        font-size: 18px;
       }
    span.two {
        font-size: 28px;
    }

    @media (max-width: $layout-breakpoint-small) {
        margin: 0.5em 1em 0 1em;
        border-bottom: none;
        font-size: medium;
    a {
        width: auto;
        left: auto;
        margin-left: 0;
        }
    a img {
        max-width: 85%;
        }
    }
} 

/* -----------------------------------------------------------------------------
    Navigation
------------------------------------------------------------------------------*/

body > nav {
    position: sticky;
    top: -195px;

    padding: 0 0;
    background-image: url('/src/static/theme/header-fenster.jpg');
    background-position: 0 100%;
    background-repeat: no-repeat;
    -moz-background-size: cover;
    background-size: cover;

    box-shadow: 0 1px 0 silver, 0 -1px 0 silver;
    border-width: 3px 0;
    border-style: solid;
    border-color: white;

    ul {
        display: block;
        padding: 0;
        height: 250px;

        @media (max-width: $layout-breakpoint-small) {
            height: auto;
        }
    }

    li {
        position: relative; 
        top: 185px;
        height: 80px;
        float: left;
        display: block;
        list-style: none;
        margin: 0;

        @media (max-width: $layout-breakpoint-small) {
            top: auto;
            height: auto;
            float: none;
        }
    }
    
    li.first 			    { 
        background-color: rgba(245,0,24,0.7); 
        @media (max-width: $layout-breakpoint-small) {
            background-color: transparent;
        }
    }
    li.last 			    { 
        background-color: rgba(37,16,94,0.79); 
        @media (max-width: $layout-breakpoint-small) {
            background-color: transparent;
        }
    }
    li.current_page 	    { background: rgba(103,39,79,0.7) url(/src/static/theme/current.png) no-repeat 50% 100%; }
    li:hover 			    { background: rgba(220,50,0,0.7) none no-repeat 50% 100%; }
    li.current_page:hover 	{ background-image: url(/src/static/theme/current.png);  }
    
    li.current_page a:before {
        @media (max-width: $layout-breakpoint-small) {
            content:  "\2192  ";
        }
    }

    li a {
        display: block;
        text-transform: uppercase;
        color: white;
        font-size: x-large;
        font-weight: 400;
        text-decoration: none;
        text-shadow: 1px 1px 2px black;
        padding: 0.5em 0.75em 40px 0.75em;

        @media (max-width: $layout-breakpoint-small) {
            font-size: large;
            padding: 3px 0.5em
        }

        }
    
    li.first a {
        background: url(/src/static/theme/home.png) no-repeat center 0.65em;
        width: 80px;
        text-indent: -999em;

        @media (max-width: $layout-breakpoint-small) {
            background-position: 10px center;
            text-indent: 25px;
            width: auto; 
        }
    }

    li.last {
        float: right;
        @media (max-width: $layout-breakpoint-small) {
            float: none;
        }
    }

}

/* -----------------------------------------------------------------------------
    Layout
------------------------------------------------------------------------------*/

main,
body > nav ul,
body > footer ul  {
    width: $layout-lg-container-width;
    margin: 0 auto;

    @media (max-width: $layout-breakpoint-small) {
        width: auto;
        margin: 0;
    }
}

main {
    padding: 1em 0 2em 0;
    clear: both;
    
    @media (max-width: $layout-breakpoint-small) {
        padding: 0 1em;
    }
}

section h2  {
    padding-top: 70px;
    @media (max-width: $layout-breakpoint-small) {
        padding-top: 0.5em;
    }
}

/* -----------------------------------------------------------------------------
    Two Column
------------------------------------------------------------------------------*/

.col1 {
    width: 53%;
    float: left;
    }
.col2 {
    width: 40%;
    float: right;
    padding-right: 0;
    }

.d-flex {
    display: flex;
}
.d-flex-main {
    flex: 1;
    padding-right: 2em;
}
.d-flex-sidebar {
    width: 180px;

    @media (max-width: $layout-breakpoint-small) {
        display: none;
    }
}

/* -----------------------------------------------------------------------------
    TOC
------------------------------------------------------------------------------*/
.toc {
    position: sticky;
    top: 100px;
    padding-top: 20px;

    h6 {
    font-size: x-large;
    margin-top: 0;
    }
    a,
    aside a:visited {
        display: block;
        text-decoration: none;
        color: rgb(0, 0, 238);
    }
    a:hover,
    a:active {
        font-weight: 400;
    }
}


/* -----------------------------------------------------------------------------
    Footer
------------------------------------------------------------------------------*/
body > footer {
    clear: both;
    padding: 1em 2em;
    text-align: right;
    font-size: medium;

    ul {
        padding: 0;
        overflow: hidden;
    }
    li {
        margin: 0 0.25em;
        display: block;
        float: left;
        margin-right: 1em;
        list-style-type: none;
        float: right;
    }
    li.first {
        float: left;
    }
    li.first a {
        padding-left: 20px;
        background: url(/src/static/theme/top.png) no-repeat 0 50%;
    }
    li a {
        text-decoration: none;
    }

    @media (max-width: $layout-breakpoint-small) {
        padding: 1em 0;

        li {
            float: none;
        }
        li a {
            display: block;
            padding-top: 0.25em;
            padding-bottom: 0.25em;
            text-align: right;
        }
        li.first a {
            padding-right: 20px;
            background-position: 100% 50%;
        }
    }
}

/* -----------------------------------------------------------------------------
SmallScreen
----------------------------------------------------------------------------- */
@media (max-width: $layout-breakpoint-small) {
    body {
        min-width: auto;
        font-size: medium;
        }

    h2, h3, h4, h5, h6, .page--dozenten main dt {
        font-size: x-large;
        line-height: 120%;
        margin: 0.5em 0;
        }

    .col1,
    .col2 {
        width: auto;
        float: none;
        }

    blockquote footer {
        display: block;
        padding-top: 0.5em;
        text-align: right;
        font-style: normal;
        font-size: small;
        }
    blockquote:before {
        display: none;
    }
}
