body {
	font: 12pt Georgia, "Times New Roman", Times, serif;
	line-height: 1.3;
	color: #000;
	}

#LME_maplinks, 
#toc, 
#search, 
#nav, 
#footer {
	display: none
	}

h2, h3, h4, h5, h6 {
	margin: 2em 0 1em 0; 
	font-weight: normal; 
	}

h2 {	font-size: xx-large; }
h3 {	font-size: x-large; }
h4 {	font-size: large; }


th, td {
	text-align: left; 
	padding: 2px 20px 2px 0; 
	}

.deco {
	border-top: 1px solid gray; 
	margin: 1em 0; 
	}
.deco hr {
	display: none; 
	}

.dozenten dt {
	font-size: x-large; 
	margin: 2em 0 1em 0; 
	clear: both; 
	}
.dozenten .content img {
	float: right; 
	width: 200px; 
	margin: 0 0 2em 2em; 
	}

h1 a {
	text-decoration: none; 
	}

h1:after {
	display: block;
	font-size: 12pt; 
	font-weight: normal;
	padding-left: 80px; 
	content: "www.waldorflehrerseminar-freiburg.de"; 
	}

img {border: 0; max-width: 100%; }

#accordion > div {
    display: block !important; 
}

blockquote {
	margin: 2em; 
	font-style: italic; 
	}
blockquote footer {
	display: block; 
	padding-top: 0.5em; 
	text-align: right; 
	font-style: normal; 
	}

.content a {
	text-decoration: none; 
	}

/* Show the URL after each link, whereby internal links are preceeded by the site's URL */
.content a:before {
	content: "→ ";
}

/* External links don't get this treatment */
.content a[href^="http://"]:after, p a[href^="https://"]:after {
	content: " (" attr(href) ")";
	font-size: small; 
}
